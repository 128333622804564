import React, { createContext } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

export const LangContext = createContext();

const LangContextProvider = props => {
  const [lang, setLang] = useState([]);

  const importLang = async lang => {
    let res = await (await import(`../lang/${lang}`)).default;
    setLang(res);
  };

  useEffect(() => {
    if (!localStorage.getItem('lang')) {
      localStorage.setItem('lang', 'uz');
    }
    const lang = localStorage.getItem('lang');
    importLang(lang);
  }, []);

  const changeLang = lang => {
    localStorage.setItem('lang', lang.key);
    importLang(lang.key);
  };

  return <LangContext.Provider value={{ lang, changeLang }}>{props.children}</LangContext.Provider>;
};

export default LangContextProvider;
