import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ConnectedRouter } from 'react-router-redux';
import { Provider } from 'react-redux';
import { Route } from 'react-router-dom';
import ScrollToTop from 'components/ScrollToTop';
import App from './App';

import { ConfigProvider } from 'antd';
import ru_RU from 'antd/es/locale/ru_RU';
import LangContextProvider from '../context/LangContext';

export default class Root extends Component {
  render() {
    const { store, history } = this.props;
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <ScrollToTop>
            <LangContextProvider>
              <ConfigProvider locale={ru_RU}>
                <Route path="/" component={App} />
              </ConfigProvider>
            </LangContextProvider>
          </ScrollToTop>
        </ConnectedRouter>
      </Provider>
    );
  }
}

Root.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};
